<template>
    <div id="tips">
        <div v-html="message"></div>
    </div>
</template>

<script>
export default {
    name: "tips",
    data() {
        return {
            message: ''
        };
    },
    created() {
        this.getTips();
    },
    methods: {
        getTips() {
            this.$post('api/index/tipDetail')
                .then(res => {
                    this.message = res.datas.detail;
                });
        }
    }
};
</script>
<style>
    #tips div {
        padding: 35px 25px;
    }
</style>